import React from "react";
import PropTypes from "prop-types";
import Quote from "assets/icons/quote.svg";
import * as QuoteBlockStyling from "./Quote.module.scss";

const QuoteBlock = ({ quote, author, hasBackgroundColor, backgroundcolor }) => {
  return (
    <figure
      className={`${QuoteBlockStyling.blockquote} ${hasBackgroundColor ? QuoteBlockStyling.margin : ""
        }`}
      style={{
        backgroundColor:
          hasBackgroundColor && backgroundcolor
            ? backgroundcolor.hex
            : "white",
      }}
    >
      <Quote
        className={`${QuoteBlockStyling.quote} ${QuoteBlockStyling.quoteFirst}`}
      />
      <blockquote className="text5">{quote}</blockquote>
      <Quote
        className={`${QuoteBlockStyling.quote} ${QuoteBlockStyling.quoteSecond}`}
      />
      <figcaption>{author}</figcaption>
    </figure>
  );
};

export default QuoteBlock;
QuoteBlock.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  hasBackgroundColor: PropTypes.bool,
  backgroundcolor: PropTypes.object,
};
